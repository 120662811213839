import React, { useState, useEffect } from "react"
import { useStoryblokState, storyblokEditable } from "gatsby-source-storyblok"
import RichTextRenderer from 'utils/RichTextRenderer'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import '../../static/styles/modular-page.css'
import '../../static/styles/homepage.css'
import HomepageVideo from '../components/HomepageVideo'
import Seo from "components/Seo"


const ModularPage = ({
  data: { modularPage },
  path,
}) => {
  // This will be used to set live editor preview data
  const [previewStory, setPreviewStory] = useState(null);

  // Parse Storyblok data
  let story = useStoryblokState(modularPage, { resolveLinks: "url" });

  // I can't remember why this was needed, so let's first test the Storyblok editor experience with it and then without it
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [story]);

  // If inside Storyblok editor, execute this
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {

      // Storyblok API endpoint
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${modularPage.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}`;

      // Fetch preview data from Storyblok API
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();

      // Let's wait until the Storyblok script has loaded
      const waitForStoryblokToLoad = function () {
        if (!window.StoryblokBridge) {
          setTimeout(waitForStoryblokToLoad, 100)
        } else {
          const { StoryblokBridge } = window
          const storyblokInstance = new StoryblokBridge()

          // Update live preview when Storyblok story changes
          storyblokInstance.on('input', (event) => {
            setPreviewStory(event.story);
          })
        }
      }

      waitForStoryblokToLoad();
    }
  }, []);

  // Conditional that checks whether to show original GraphQL data or the preview data
  const content = previewStory ? previewStory.content : story.content;

  useEffect(() => {
    if (story?.content?.headerScript) {
      try {
        // Create a new function from the script string and execute it
        const executeScript = new Function(story.content.headerScript);
        executeScript();
      } catch (error) {
        console.error('Error executing script:', error);
      }
    }
  }, [story]); // Re-run if page changes

  return (
    <>
      <Seo
        title={content.title || `${modularPage.name} | QuickMail`}
        url={modularPage.path || modularPage.slug}
      />
      
      {content.bubbleVideo && <HomepageVideo />}
      <article id="modularPage" {...storyblokEditable(content)}>
        <RichTextRenderer data={content.content} />
      </article>
    </>
  )
}

export default ModularPage

export const ModularPageQuery = graphql`
  query ModularPageQuery(
    $slug: String!
  ) {
    modularPage: storyblokEntry(slug: { eq: $slug }) {
      content
      name
      slug
      path
      full_slug
    }
  }
`

