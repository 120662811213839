import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import '../../static/styles/homepagevideo.css'

export default () => {
  const [loadVideo, setLoadVideo] = useState(false);
  const [videoScript, setVideoScript] = useState(false);
  const refVideo = useRef(null);
  const wistiaTrigger = useRef(null);

  useEffect(() => {
    // simply creating a timeout event
    const timeout = setTimeout(() => {
      // only run the script if the video isn't present on the page
      if (
        !document.getElementById(
          'embed.so-d851d9aa-6bfe-4cc2-942f-4008c0d18122'
        )
      ) {
        // add the configuration to the window object as stated in the documentation
        window.EMBED_SO_CONFIG = {
          id: 'd851d9aa-6bfe-4cc2-942f-4008c0d18122',
          position: 'left',
        }

        // change the videoScript state to render the video and scripts
        setLoadVideo(true);

        // Mute the video so the DOM picks it up
        refVideo.current.defaultMuted = true;
        refVideo.current.muted = true;
      }
    }, 3000)

    // clear the timeout if the page is left before the video loads
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (videoScript) {
      // Let's wait until the Wistia script has loaded
      const waitForWistiaToLoad = function () {
        if (!window.Wistia) {
          setTimeout(waitForWistiaToLoad, 25);
        } else {
          setTimeout(() => {
            document.body.classList.add('wistia_popover_mode');
          }, 35);
        }
      }

      waitForWistiaToLoad();
    }
  }, [videoScript])

  return (
    <>
      {loadVideo &&
        <div className="embeddedvideo">
          <span
            className="wistia_embed wistia_async_qyctu4lzme popover=true popoverContent=link"
            style={{
              display: 'inline-block',
              height: '84px',
              position: 'relative',
              width: '150px',
            }}
            onClick={() => setVideoScript(true)}
          >
            <video
              className="embeddedvideo"
              src="/assets/welcome.mp4"
              ref={refVideo}
              autoPlay
              muted
              playsInline
              loop
            />
          </span>
        </div>
      }

      {videoScript &&
        <Helmet>
          <script
            src="https://fast.wistia.com/embed/medias/qyctu4lzme.jsonp"
            async
          ></script>
          <script
            src="https://fast.wistia.com/assets/external/E-v1.js"
            async
          ></script>

          <style>
            {`
              .wistia_popover_mode .embeddedvideo {
                display: none;
              }

              .wistia_popover_mode {
                width: 100%;
                overflow-x: hidden;
              }
              
              .wistia_popover_mode .wistia_popover_embed {
                left: 50% !important;
                top: 50% !important;
                transform: translate(-50%, -50%) !important;
                z-index: 1051;
                position: fixed !important;
                transition: unset !important;
              }
              
              .wistia_popover_mode .wistia_placebo_close_button {
                position: fixed !important;
                left: 0 !important;
                top: 0 !important;
                width: 100% !important;
                height: 100% !important;
                z-index: 1050 !important;
              }
              
              .wistia_popover_mode .wistia_popover_embed::after {
                content: '';
                width: 200vw;
                height: 200vh;
                top: -50vh;
                left: -50vw;
                position: absolute;
                background-color: rgba(0,0,0, 0.5);
                z-index: -1;
                user-select: none;
                pointer-events: none;
              }
              
              .wistia_popover_mode .wistia_popover_overlay {
                position: fixed !important;
                left: 0 !important;
                top: 0 !important;
                background-color: transparent !important;
              }
            `}
          </style>
        </Helmet>
      }
    </>
  )
}
